@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Alice&family=Baloo+2:wght@400;800&family=Bree+Serif&family=Montserrat:ital,wght@0,500;0,700;1,700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

@layer components {
  .WalletButtons {
    @apply flex w-full bg-white/5 py-4 px-5 relative items-center gap-5
  }
  .contactFormInput{
    @apply bg-transparent outline-none w-full border-b px-3 py-2 border-gray-700
  }
}

.bg-primary{
  background-color: #0d1f2d;
}

.dark .dark\:text-sky-500{
  color: #afffff !important;
  /* color: #4a9d9c !important;#afffff */
}

.bg-sky-800{
  background-color: #0d1f2d !important;
}

.text-sky-800{
  color: #0d1f2d !important;
}

.dark .dark\:text-white{
  color: #afffff !important;
}

.dark .dark\:bg-primary{
  background-color: #4a9d9c !important;
}

.dark .dark\:bg-content{
  background-image: none !important;
  background-color: #354656 !important;
}

.dark .dark\:bg-sky-500 {
  background-color: #4a9d9c !important;
}

.dark .dark\:hover\:border-sky-500:hover{
  border-color: #afffff !important;
  
}

.shadow-sky-500\/30{
  --tw-shadow-color: #afffff7b
}



.chartContainer {
  width: 100px;
  height: 100px;
  max-width: 120px;
}

/* arrow down after thr hr line in center */
.arrow {
  background-color: gainsboro;
  width: 100%;
  height: 1px;
  position: relative;
}

.arrow-down {
  position: absolute;
  top: 1000%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #fff;
}

/* Banner */
.header-content::after {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background-image: url(https://billionercyber.com/assets/img/header-bg-1.png);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: auto;
  content: "";
  z-index: 5;
}

.header-content {
  /* background-image: url("../public/img/header-bg.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 600px;
  overflow: hidden;
  position: relative;
  z-index: 20;
}

.header-content:after {
  z-index: -1;
  position: absolute;
  width: 3980px;
  height: 100%;
  left: 0;
  bottom: 0;
  background-image: url("../public/img/header-grid.png");
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: auto;
  content: "";
  -webkit-animation: grid 200s infinite linear;
  -moz-animation: grid 200s infinite linear;
  animation: grid 200s infinite linear;
  pointer-events: none;
}

.header-scene__item {
  width: 100%;
  height: 100%;
}

.header-scene__item img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.header-scene__item canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0.5;
  width: 250%;
  height: 150%;
}

.header-scene__item:nth-child(1) {
  z-index: 3;
}

.header-scene__item:nth-child(2) {
  z-index: 2;
}

.header-scene__item:nth-child(2) img {
  -webkit-animation: rotate 10s infinite linear;
  -moz-animation: rotate 10s infinite linear;
  animation: rotate 10s infinite linear;
}

.header-scene__item:nth-child(3) {
  z-index: 1;
}

.header-scene__item:nth-child(3) img {
  -webkit-animation: rotateR 30s infinite linear;
  -moz-animation: rotateR 30s infinite linear;
  animation: rotateR 30s infinite linear;
}

.header-scene__item:nth-child(4) {
  z-index: 1;
}

.custom_shadow {
  transition: all 0.1s ease-in-out;
}

.custom_shadow:hover {
  box-shadow: 0px 8px 16px rgb(24 26 32 / 16%);
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc !important;
  color: #666666;
  cursor: not-allowed;
}

.rotate_x {
  transform: rotate(260deg);
}

.rotate_y {
  transform: rotate(180deg);
}

.modal_box {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(5px);
}

.modal_box::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
  content: "";
  opacity: 0.5;
  -webkit-backdrop-filter: blur(33px);
  backdrop-filter: blur(33px);
  backdrop-filter: blur(5px);
}

/* .mint_modal_box ::before {
  backdrop-filter: blur(5px);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #171c21;
  content: "";
} */

.mint_modal_box {
  position: absolute;
  top: 50%;
  left: 50%;
  /* width: 100%; */
  opacity: 1.1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@-webkit-keyframes rotateR {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-moz-keyframes rotateR {
  0% {
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    -moz-transform: translate(-50%, -50%) rotate(-360deg);
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes rotateR {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
    -moz-transform: translate(-50%, -50%) rotate(-360deg);
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-webkit-keyframes grid {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-moz-keyframes grid {
  0% {
    -moz-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  100% {
    -moz-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes grid {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    transform: translateX(0%);
  }
}





#Core-Team-Page-Circle { position: relative; width: 100%; 
   overflow: hidden; }

#Core-Team-Page-Circle text { font-family: 'Helvetica Neue', Arial;   font-size: 30px; font-weight: bold; }

#Core-Team-Page-Circle svg { 
  /* position: absolute; left: 0; top: 0; width: 100%; height: 540px; */


  -webkit-animation-name: rotate;
     -moz-animation-name: rotate;
      -ms-animation-name: rotate;
       -o-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 5s;
     -moz-animation-duration: 5s;
      -ms-animation-duration: 5s;
       -o-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
     -moz-animation-iteration-count: infinite;
      -ms-animation-iteration-count: infinite;
       -o-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
     -moz-animation-timing-function: linear;
      -ms-animation-timing-function: linear;
       -o-animation-timing-function: linear;
          animation-timing-function: linear;

}

@-webkit-keyframes rotate {
    from { -webkit-transform: rotate(360deg); }
    to { -webkit-transform: rotate(0); }
}
@-moz-keyframes rotate {
    from { -moz-transform: rotate(360deg); }
    to { -moz-transform: rotate(0); }
}
@-ms-keyframes rotate {
    from { -ms-transform: rotate(360deg); }
    to { -ms-transform: rotate(0); }
}
@-o-keyframes rotate {
    from { -o-transform: rotate(360deg); }
    to { -o-transform: rotate(0); }
}
@keyframes rotate {
    from { transform: rotate(360deg); }
    to { transform: rotate(0); }
}

